import { initializeApp } from "firebase/app";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { showMessage } from './helpers.js';

const firebaseConfig = {
    apiKey: 'AIzaSyC3Ttl9PB-5CCUiR3vucpAnjIG7BMtaTwo',
    authDomain: 'lerobin-882d8.firebaseapp.com',
    databaseURL: 'https://lerobin-882d8.firebaseio.com',
    projectId: 'lerobin-882d8',
    storageBucket: 'lerobin-882d8.appspot.com',
    messagingSenderId: '892167762181'
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

auth.onAuthStateChanged((user) => {
    if (!user && !window.location.pathname.includes('login')) {
        window.location.href = "login.html";
    }
    if (user && window.location.pathname.includes('login')) {
        window.location.href = "/";
    }
});

if (document.getElementById('registerButton')) {
    document.getElementById('registerButton').addEventListener('click', () => {
        const email = document.getElementById('registerEmail').value;
        const password = document.getElementById('registerPassword').value;
        createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                const user = userCredential.user;
                console.log('Registered:', user);
                window.location = '/';
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.error(errorCode, errorMessage);
                showMessage(errorMessage);
            });
    });
}

if (document.getElementById('loginButton')) {
    document.getElementById('loginButton').addEventListener('click', () => {
        const email = document.getElementById('loginEmail').value;
        const password = document.getElementById('loginPassword').value;
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                const user = userCredential.user;
                console.log('Logged in:', user);
                window.location = '/';
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                showMessage(errorMessage);
            });
    });
}

const inputLoginPassword = document.querySelector('input#loginPassword');
if (inputLoginPassword) {
    inputLoginPassword.addEventListener('keypress', (e) => {
        if (e.key === 'Enter') {
            const email = document.getElementById('loginEmail').value;
            const password = inputLoginPassword.value;
            signInWithEmailAndPassword(auth, email, password)
                .then((userCredential) => {
                    const user = userCredential.user;
                    console.log('Logged in:', user);
                    window.location = '/';
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    showMessage(errorMessage);
                });
        }
    });
}

if (document.getElementById('logoutButton')) {
    document.getElementById('logoutButton').addEventListener('click', () => {
        signOut(auth).then(() => {
            showMessage('User signed out.');
        }).catch((error) => {
            console.error('Sign out error:', error);
            showMessage(error);
        });
    });
}
